<template>
    <vx-card>
        <div class="text-center">
            <img v-if="notification.icon" :src="require(`@/assets/icons/${notification.icon}.svg`)" width="70px" class="inline-block align-middle mr-5" />
            <h3 class="inline-block text-info">{{notification.title}}</h3>
        </div>

        <p>{{ notification.msg }}</p>

        <div v-if="notification.data" class="mt-5">
            <vs-collapse>
                <vs-collapse-item v-for="(data, i) in notification.data" v-bind:key="i">
                    <div slot="header">
                        Fila {{ data.row }}
                    </div>
                    
                    <p v-for="(record, i) in data.records_failed" v-bind:key="i">{{ record }}</p>
                </vs-collapse-item>
            </vs-collapse>
        </div>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    
    created() {
        const notificationId = this.$router.currentRoute.params.id;

        this.$store.dispatch( 'updatePageTitle', {
            title: 'Notificaciones',
            subtitle: '',
        });

        this.$store.dispatch( 'notification/getNotification', notificationId )
            .then( res => {
                if( !res ) {
                    this.$router.push( { path: '/app' } );
                    return;
                }
            });

    },

    computed: {
        notification() {
            return {...this.$store.state.notification.notification};
        },
    }

})
</script>